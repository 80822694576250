import { isAdmin } from '../services/AccountService'
import fxStyles from '../utils/styles/fxStyles'

const commonStyles = (theme) => {
    return {
        drawerTopBar: {
            height: 'auto',
            background: theme.palette.primary.light,
            ...fxStyles.alnCenter,
            ...fxStyles.jstSB,
            padding: '10px 8px',
            position: 'fixed',
            zIndex: 10,
            width: '380px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        publicContentPaper: {
            borderRadius: '20px',
            padding: '32px',
            width: '392px',
        },
        drawerContent: {
            flex: '1 1 auto',
            overflow: 'auto',
        },

        tableRowClickable: {
            cursor: 'pointer',
            '&:hover': {
                boxShadow: '0px 0px 8px rgba(254, 106, 41, 0.8)',
            },
        },

        button: {
            fontFamily: "'PT Root UI',sans-serif",
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: 1,
            textTransform: 'uppercase',
        },
        smallButton: {
            height: '32px',
            padding: '0 16px',
            borderRadius: '4px',
            fontSize: '12px',
            lineHeight: 1.2,
        },
        mediumButton: {
            height: '40px',
            padding: '0 16px',
            borderRadius: '4px',
        },
        largeButton: {
            height: '64px',
            padding: '16px 24px',
            borderRadius: '8px',
            fontSize: '20px',
        },
        containedPrimaryButton: {
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
            '&:hover': { backgroundColor: theme.palette.blue.active },
        },
        textButton: {
            background: 'transparent',
        },
        buttonActive: {
            transition: 'all 0.3s ease-in-out',
            '&:active': {
                transform: 'scale(0.95)',
                filter: 'blur(1px)',
            },
        },
        selectFieldMedium: {
            height: '40px',
            borderRadius: '4px',
            '& .MuiSelect-icon': {
                right: '16px',
            },
        },
        selectFieldSmall: {
            height: '32px',
            borderRadius: '4px',
            fontSize: '12px',
            '& .MuiSelect-icon': {
                top: 'calc(50% - 10px)',
                right: '10px',
            },
        },
        selectFieldSecondary: {
            background: theme.palette.secondary.dark,
        },
        selectField: {
            border: `1px`,
            borderRadius: '4px',
            letterSpacing: '0',
            // '& .MuiOutlinedInput-notchedOutline': {
            //     borderColor: theme.palette.primary.main,
            // },

            '& .MuiSelect-select': {
                paddingRight: '36px !important',
            },

            '& .MuiTypography-subtitle1': {
                fontWeight: '400 !important',
            },
        },
        selectMenu: (props) => ({
            '& .MuiMenu-paper': {
                borderRadius: '4px',
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                border: '1px solid #FFF', //я не понял для чего эта рамка используется был цвет #FF7426
                width: props.styles?.width,
                margin: props.styles?.margin,
            },
        }),
        menuItem: {
            height: '36px',
            borderRadius: '8px',
            margin: '4px 0',
            color: theme.palette.text.main,
            '&.Mui-selected': {
                background: theme.palette.secondary.dark,

                '&:hover': {
                    background: theme.palette.secondary.light,
                },
            },
            '&:hover': {
                background: theme.palette.secondary.light,
            },
            '&.MuiTablePagination-menuItem': {
                fontSize: '0.875rem',
                fontWeight: '500',
                borderRadius: '0',
            },
        },

        menuItemSmall: {
            fontSize: '12px',
            height: '24px',
            borderRadius: '4px',
            margin: '4px 0',
        },
        beigeContainer: {
            background: theme.palette.background.paper,
            borderRadius: '12px',
        },
        dialogHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '24px 48px',
            backgroundColor: theme.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.light,
        },
        dialogCrossContainer: {
            borderRadius: '50%',
            backgroundColor: theme.palette.secondary.main,
            width: 28,
            height: 28,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
        },
        fieldError: {
            color: 'error.main',
            fontWeight: '500',
            marginTop: '2px',
            letterSpacing: '0',
        },
        fieldErrorAbsolute: {
            position: 'absolute',
            bottom: '-16px',
            left: '5px',
            zIndex: 2,
        },
        beigeCrossButton: {
            background: theme.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.main,
            width: 28,
            height: 28,
            borderRadius: '50%',
            cursor: 'pointer',
            ...fxStyles.center,

            '&:hover': {
                background: theme.mode === 'dark' ? theme.palette.text.bleak : theme.palette.secondary.dark,
            },
        },
        searchIconBlock: {
            width: '48px',
            height: '48px',
            background: theme.palette.primary.main,
            borderRadius: '12px',
            ...fxStyles.center,
        },
        input: {
            height: '40px',
            boxSizing: 'border-box',
        },
        outlinedInput: {
            borderColor: theme.palette.gray.main,
        },
        commonButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            padding: '4px',
        },
        adminBtn: {
            padding: '4px',
            borderRadius: '4px',
            '&:hover': {
                background: 'rgba(33, 151, 234, 0.30)',
                boxShadow: '0px 0px 2px 0px rgba(33, 151, 234, 0.30)',
            },
        },

        btn: {
            borderRadius: '50%',
            padding: '4px',
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            '&: hover': {
                background: 'rgba(33, 151, 234, 0.30)',
                boxShadow: '0px 0px 2px 0px rgba(33, 151, 234, 0.30)',
            },
        },
        adminAccInfo: {
            color: theme.palette.text.white,
            display: 'flex',
            alignItems: 'center',
            padding: '4px',
            borderRadius: '4px',
            gap: '8px',
            '&:hover': {
                background: 'rgba(33, 151, 234, 0.30)',
                boxShadow: '0px 0px 2px 0px rgba(33, 151, 234, 0.30)',
            },
            marginRight: '16px',
            [theme.breakpoints.down(480)]: {
                display: 'none',
            },
        },
        drawerStatusesInfo: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
        },
        searchBox: {
            backgroundColor: theme.palette.gray.light,
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
            borderRadius: '8px',
            padding: '4px 15px',
            position: 'relative',
            width: '100%',
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'max-content 1fr 24px',
            zIndex: 1001,
            [theme.breakpoints.down('sm')]: {
                padding: '10px 15px',
            },
        },
        filterOpenBtn: {
            padding: '4px',
            width: '32px',
            height: '32px',
            '&:hover': {
                background: theme.palette.grey.main,
            },
            [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                top: 3,
                left: 0,
                width: '100%',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: '15px',
            },
        },
        photoIcon: {
            position: 'absolute',
            top: 0,
            right: 0,
            background: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
        },
    }
}

export default commonStyles
