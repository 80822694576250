import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import { css } from '@emotion/css'
import inputFieldStyles from '../styles/inputFieldStyles'
import { getStyles } from '../../../../utils/utils/commonUtils'

function TextInputField({ styles, propName, api, disabled, ...props }) {
    const classes = getStyles(inputFieldStyles)
    const { value } = props

    return (
        <TextField
            variant={'outlined'}
            autoFocus={!!props.autoFocus}
            className={classNames(classes.textField({ styles, ...props }), props.fullWidth && css(classes.fullWidth))}
            value={value}
            sx={styles}
            disabled={disabled}
            {...props}
        />
    )
}

export default observer(TextInputField)
