import {observable} from "mobx"

const modals = observable([])
export const isModalOpen = observable({})
export const modalData = observable({})
export const toggleModalFn = observable({})
export const openModalFn = observable({})
export const closeModalFn = observable({})

export function regModal(modalName) {
    if (modals.slice().includes(modalName)) {
        throw new Error(`Modal "${modalName}" is already registered.`)
    } else if (!modalName) {
        throw new Error(`Modal name must be provided.`)
    }
    modals.push(modalName)
    setModalData(modalName, {})
    isModalOpen[modalName] = false
    toggleModalFn[modalName] = (data) => {
        if (isModalOpen[modalName]) {
            isModalOpen[modalName] = false
            setTimeout(() => {
                modalData[modalName] = {}
            }, 100)
        } else {
            if (data) {
                setModalData(modalName, data)
            }
            isModalOpen[modalName] = true
        }

    }
    openModalFn[modalName] = (data) => {
        if (data) {
            setModalData(modalName, data)
        }
        isModalOpen[modalName] = true
    }
    closeModalFn[modalName] = () => {
        isModalOpen[modalName] = false
        if (modalData[modalName]) {
            setTimeout(() => {
                modalData[modalName] = {}
            }, 100)
        }
    }
}

export function setModalData(modalName, data) {
    if (!modals.slice().includes(modalName)) {
        regModal(modalName)
    }
    modalData[modalName] = data
}

export function disposeModal(modalName) {
    modals.splice(modals.indexOf(modalName), 1)
    delete isModalOpen[modalName]
    delete toggleModalFn[modalName]
    delete openModalFn[modalName]
    delete closeModalFn[modalName]
}