import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { getStyles } from 'src/utils/utils/commonUtils'
import Paper from '@mui/material/Paper'
import { css } from '@emotion/css'
import MenuItem from '@mui/material/MenuItem'
import classNames from 'classnames'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import check from 'src/assets/images/common/check.svg'
import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useIntl } from 'react-intl'

const styles = (theme, props) => ({
    autocompleteField: {
        // height: 48,
        background: theme.palette.background.default,
        borderRadius: 8,
    },
    districtInputFocused: {
        boxShadow: (props) => `0 0 0 2px ${props.borderColor ? props.borderColor : '#A1A1A1'}`,
    },
    autocompleteFieldInput: {
        padding: '0 4px !important',
    },
    popupIndicator: {
        padding: 0,
        marginRight: '16px',
        '&:hover': {
            background: 'transparent',
        },
    },
    autocompleteListbox: {
        padding: 0,
    },
    currencyName: {
        fontSize: '18px',
        lineHeight: '28px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '300px',
        fontWeight: '400 !important',
    },
    menuItem: {
        borderRadius: '8px',
        width: '100%',
        background: '#FFF',
    },
    menuItemSelected: {
        background: '#EBF4FF !important',
    },
    option: {
        borderRadius: '8px',
        backgroundColor: '#F6ECDF !important',
        padding: 0,
        margin: '2px 0',
    },
    paper: {
        padding: '12px',
        margin: '6px 0',
        boxShadow: (props) =>
            `0 0 0 2px ${props.borderColor ? props.borderColor : '#A1A1A1'}, 0px 12px 16px -8px rgba(52, 52, 52, 0.12)`,
    },
})

function DefaultAutocomplete({
    value,
    onChange,
    error,
    label,
    placeholder,
    items,
    autocompleteOptions,
    handbookCollection,
    ...props
}) {
    const intl = useIntl()
    const classes = getStyles(styles)
    const [isDropdownOpen, setDropdownOpen] = useState(false)
    const selectPaper = (options) => <Paper {...options} className={css(classes.paper)} />

    const renderOption = (option) => {
        const selected = value === option.key
        return (
            <MenuItem
                value={option.key}
                className={classNames(css(classes.menuItem), selected && css(classes.menuItemSelected))}
                onClick={(e) => {
                    onChange(option.key)
                    setDropdownOpen(false)
                }}
            >
                <Box
                    width={1}
                    display={'flex'}
                    flexWrap={'nowrap'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <Typography variant={'subtitle1'} className={css(classes.currencyName)}>
                        {option.key}
                    </Typography>
                    {/* {selected && (<img alt="check icon" src={check} width={"16px"} height={"16px"}/>)} */}
                </Box>
            </MenuItem>
        )
    }

    const onOpenSelect = (e) => {
        setDropdownOpen(true)
    }

    const onCloseSelect = () => {
        setDropdownOpen(false)
    }

    return (
        <>
            <Autocomplete
                clearOnBlur={false}
                value={value}
                inputValue={value}
                onInputChange={onChange}
                onChange={(event, value) => onChange(value)}
                options={handbookCollection}
                open={isDropdownOpen}
                disableClearable
                onOpen={onOpenSelect}
                getOptionLabel={(e) => e}
                onClose={onCloseSelect}
                PaperComponent={selectPaper}
                noOptionsText={intl.formatMessage({ id: 'Нет совпадений' })}
                renderOption={(option) => renderOption(option)}
                classes={{
                    option: css(classes.option),
                    popupIndicator: css(classes.popupIndicator),
                    listbox: css(classes.autocompleteListbox),
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            placeholder={placeholder}
                            label={label}
                            error={error}
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                classes: {
                                    input: css(classes.autocompleteFieldInput),
                                },
                            }}
                            className={classNames(
                                css(classes.autocompleteField),
                                isDropdownOpen && css(classes.districtInputFocused)
                            )}
                            {...props}
                        />
                    )
                }}
                {...props}
            />
        </>
    )
}

export default observer(DefaultAutocomplete)
