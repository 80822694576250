import React, {useEffect, useRef, useState} from "react"
import {observer} from "mobx-react"
import {getStyles} from "../../../utils/utils/commonUtils"
import inputFieldStyles from "../InputField/styles/inputFieldStyles"
import classNames from "classnames"
import {css} from "@emotion/css"
import {IconButton, InputBase, Box, FormControl, Checkbox, Typography} from "@mui/material"
import notStrict from "../../../assets/images/common/notStrict.svg"
import strict from "../../../assets/images/common/strict.svg"
import InputLabel from "@mui/material/InputLabel"

const TextFieldWithSettings = ({styles, placeholder, settings, ...props}) => {
    const classes = getStyles(inputFieldStyles)
    const [isOpen, setIsOpen] = useState(false)
    const menuRef = useRef(null)
    const buttonRef = useRef(null)
    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [menuRef])

    const isSelectedItem = settings.filter(setting => props.api.data[setting.settingField]).length > 0

    return (
        <FormControl
            fullWidth={props.fullWidth}
        >
            <InputLabel>{props.label}</InputLabel>
            <Box
                className={classNames(classes.textField({styles, ...props}), props.fullWidth && css(classes.fullWidth))}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid rgb(22,104,185)",
                    borderRadius: "4px",
                    position: "relative",
                    height: "48px",
                }}
            >
                <InputBase
                    sx={{ml: 1, flex: 1}}
                    {...props}
                />
                <IconButton
                    ref={buttonRef}
                    type="button"
                    sx={{mr: "12px", p: "4px"}}
                    onClick={() => {
                        if (isOpen) {
                            setIsOpen(false)
                        } else {
                            setIsOpen(true)
                        }
                    }}
                >
                    <img alt={""} src={isSelectedItem ? strict : notStrict}/>
                </IconButton>
            </Box>
            <Box
                ref={menuRef}
                className={classNames(props.fullWidth && css(classes.fullWidth))}
                sx={{
                    width: "max-content",
                    minWidth: "100%",
                    display: isOpen ? "flex" : "none",
                    flexDirection: "column",
                    position: "absolute",
                    backgroundColor: "#FFF",
                    borderRadius: "4px",
                    padding: "8px",
                    height: isOpen ? "min-content" : "0px",
                    opacity: isOpen ? 1 : 0,
                    top: "100%",
                    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                    zIndex: "500",
                    transition: "all .5 ease-in-out",
                }}
            >
                {settings.map(setting => {
                    return (
                        <Box
                            key={setting.settingName}
                            sx={{display: "flex", alignItems: "center", gap: "4px", height: "45px", cursor: "pointer"}}
                            onClick={() => {
                                props.api.data[setting.settingField] = !props.api.data[setting.settingField]
                            }}
                        >
                            <Checkbox
                                checked={props.api.data[setting.settingField]}
                            />
                            <Typography variant={"body1"}>
                                {setting.settingName}
                            </Typography>

                        </Box>)
                })}
            </Box>
        </FormControl>


    )
}

export default observer(TextFieldWithSettings)