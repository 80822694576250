const default_types = {
    IP: {
        regex: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
        maskRe: /[\d\\.]/,
        regexText: "Invalid IP",
        maxLength: 15,
    },
    Phone: {
        regex: /^((\+7|7|071|072)+([0-9]){10})$/,
        maskRe: "",
        maxLength: 13,
        regexText: "Введите валидный номер телефона",
    },
}

export default default_types

export const getDefaultType = () => {
    return default_types
}

export const getValidatorData = name => {
    const val = default_types[name]
    return !val ? null : val
}

export const required = (value, Type) => {
    const res = default_types[Type]
    const errorText = res && res.requiredText ? res.requiredText : "Provide value"
    return value ? undefined : errorText
}

export const maxLength = max => value => {
    return value && value.length > max
        ? `Value cannot be more than ${max} symbols`
        : undefined
}

export const minValue = min => value => {
    return value && value < min
        ? `Value cannot be less than ${min} symbols`
        : undefined
}

const _validate = (value, type) => {
    const res = default_types[type]
    if (!res) throw Error(`Validator value for type ${type} not defined`)
    return value && !res.regex.test(value)
        ? res.regexText || "Invalid value"
        : undefined
}

export const getValidators = (type, isRequired) => {
    if (!type && !isRequired) {
        return []
    }
    if (!type && isRequired) {
        return [value => required(value, type)]
    }

    const dataVal = default_types[type]
    if (!dataVal || !dataVal.regex || !dataVal.maxLength) throw new Error(`Validator value for type ${type} not defined`)
    const validators = []
    if (isRequired) {
        validators.push(value => required(value, type))
    }
    if (dataVal.regex) {
        validators.push(value => _validate(value, type))
    }
    if (dataVal.maxLength) {
        validators.push(maxLength(dataVal.maxLength))
    }
    return validators
}


export const validate = (value, type, isRequired) => {
    const validators = getValidators(type, isRequired)

    for (let i = 0; i < validators.length; i++) {
        const err = validators[i](value)
        if (err) {
            return err
        }
    }
    return ""
}
