const components = {}
const componentsFunc = {}

const validateInput = ({
                           componentClass,
                           componentName,
                           isSingleton = false,
                       }) => {
    if (typeof componentClass !== "function") {
        throw new Error("First parameter has to be of type Class")
    }

    if (typeof isSingleton !== "boolean") {
        throw new Error("Parameter \"isSingleTon\" has to be of type Boolean")
    }

    if (!componentName) {
        throw new Error("Component name not defined")
    }

    if (isSingleton && componentsFunc[componentName]) {
        throw new Error(
            `Component named ${componentName} is already registered in DI container`
        )
    }
}

const registerStore = (componentClass, componentName, isSingleton = false) => {
    validateInput({componentClass, componentName, isSingleton})
    componentsFunc[componentName] = {
        ComponentClass: componentClass,
        isSingleton,
    }
}

const getComp = storeName => {
    if (!storeName || typeof storeName !== "string") {
        throw new Error("Invalid component name")
    }
    const storeData = componentsFunc[storeName]
    if (!storeData) {
        throw new Error(
            `Component ${storeName} is not registered in DI container`
        )
    }

    const regStore = components[storeName]
    if (regStore) {
        return regStore
    }

    const store = new storeData.ComponentClass()

    if (storeData.isSingleton) {
        components[storeName] = store
    }

    store._name = storeName

    return store
}

export {registerStore as regComp, getComp, componentsFunc}
