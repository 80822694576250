import React from 'react'
import { observer } from 'mobx-react'
import TextInputField from './components/TextInputField'
import SelectInputField from './components/SelectInputField'
import MaskInputField from './components/MaskInputField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import commonStyles from '../../../styles/commonStyles'
import { getStyles } from '../../../utils/utils/commonUtils'
import TextFieldWithSettings from '../TextFieldWithSettings/TextFieldWithSettings'
import CustomCheckboxInput from './components/CustomCheckboxInput'
import TerritoriesAutocomplete from './components/TerritoriesAutocomplete'
import CustomAutocomplete from 'src/components/customElements/InputField/components/CustomAutocomplete'

function InputField({ type = 'text', territorySelect, ...props }) {
    const classes = getStyles(commonStyles)
    
    let Component
    switch (type) {
        case 'select':
            Component = <SelectInputField {...props} />
            break
        case 'autocomplete':
            
            territorySelect
                ? (Component = <TerritoriesAutocomplete {...props} />)
                : (Component = <CustomAutocomplete {...props} />)
            break
        case 'checkbox':
            Component = <CustomCheckboxInput {...props} />
            break
        default:
            props.mask
                ? (Component = <MaskInputField {...props} />)
                : props.settings
                ? (Component = <TextFieldWithSettings {...props} />)
                : (Component = <TextInputField {...props} />)
            break
    }
    return (
        <Box
            sx={
                !props.errorStatic &&
                !props.multiline && {
                    position: 'relative',
                    height: props.styles?.height || '40px',
                }
            }
        >
            {Component}
            {!!props.error && !props.withoutErrorText && (
                <Typography
                    variant={'caption'}
                    sx={{
                        ...classes.fieldError,
                        ...(!props.errorStatic && !props.multiline && classes.fieldErrorAbsolute),
                    }}
                >
                    {props.error}
                </Typography>
            )}
        </Box>
    )
}

export default observer(InputField)
