import { gState } from '../utils/controllers/GlobalStateController'

export const roles = {
    0: 'Unspecified',
    1: 'Администратор',
    2: 'Дилер',
    3: 'Оператор колл-центра',
}

const checkLabelRolesBol = (labelStr) => {
    const rolesArrN = window.SAT.config[labelStr]
    return (
        !!rolesArrN &&
        !!rolesArrN.length &&
        gState['roles'] &&
        rolesArrN.some((roleStr) => gState['roles'].includes(roleStr))
    )
}

export function isDashBoardAvailable() {
    return checkLabelRolesBol('Dashboard_roles')
}

export function isAdmin() {
    return checkLabelRolesBol('LabelA')
}

export function isOperator() {
    return checkLabelRolesBol('LabelC')
}

export function isDealer() {
    return checkLabelRolesBol('LabelD')
}

export function isSuspiciousDealer() {
    return checkLabelRolesBol('LabelD') && !!gState['roles'] && gState['roles'].includes('LabelWE')
}

export function isDealerOperator() {
    return checkLabelRolesBol('LabelD')
}
export function isLooker() {
    return !!gState['roles'] && gState['roles'].includes('looker')
}
export function isAdministration() {
    return checkLabelRolesBol('LabelAD')
}

export function isDataCustomizer() {
    return checkLabelRolesBol('LabelA') && !!gState['roles'] && gState['roles'].includes('LabelE')
}

export function isAdminType2() {
    return checkLabelRolesBol('LabelA') && !!gState['roles'] && gState['roles'].includes('LabelIB')
}

export function isAdminLabelAA() {
    return checkLabelRolesBol('LabelA') && !!gState['roles'] && gState['roles'].includes('LabelAA')
}
