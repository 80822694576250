export default function buildParams(data) {
    let params = ""

    Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined) {
            if (Array.isArray(value)) {
                value.forEach(value => {
                    params = params + `&${key}=${encodeURIComponent(value.toString())}`
                })
            } else {
                if (value === null) {
                    params = params + `&${key}=null`
                    return
                }
                params = params + `&${key}=${encodeURIComponent(value.toString())}`
            }
        }
    })

    return params.toString()
}