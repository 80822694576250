import {useTheme} from "@mui/material"
import React from "react"


export const getStyles = (getStyles = (() => {
}), other = {}) => {

    const theme = useTheme()
    return getStyles(theme, other)
}

export const checkMobileDevice = () => {
    const regExp = new RegExp(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i)
    return regExp.test(navigator.userAgent)
}