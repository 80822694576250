import React from 'react'
import { observer } from 'mobx-react'
import InputMask from 'react-input-mask'
import TextInputField from './TextInputField'

function MaskInputField({ value, onChange, mask, disabled, propName, ...textFieldProps }) {
    return (
        <InputMask
            mask={mask}
            onChange={onChange}
            maskPlaceholder={'/'}
            maskChar={null}
            value={value}
            disabled={disabled}
            formatChars={{
                9: '[0-9]',
                a: '[A-Za-z]',
                '*': '[A-Za-z0-9]',
            }}
        >
            {() => <TextInputField {...textFieldProps} disabled={disabled} />}
        </InputMask>
    )
}

export default observer(MaskInputField)
