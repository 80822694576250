import { css } from '@emotion/css'

const inputFieldStyles = (theme) => ({
    textField: (props) =>
        css({
            ...(props.styles || {}),
        }),
    fullWidth: {
        width: '100%',
    },
})

export default inputFieldStyles
