import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputField from './InputField/InputField'
import { Button } from '@mui/material'

function FormInput({
    onChange,
    caption,
    containerStyles,
    autoSearch,
    multiple,
    onChangeMultiple,
    validateFilterInputs,
    capitalizeFirstLetter,
    styles,
    ignoreErrorColor,
    error,
    ...props
}) {
   
    const validate = (event, propName) => {
        const numericPropNames = ['external-tracking-number']
        const propNamesNotSimbolsNumbrs = ['name', 'surname', 'patronymic']
        const propNamesNotSimbols = ['house', 'building', 'apartment', 'additionalInfo', 'address']
        if(numericPropNames.includes(propName)){
            const numericRegex = /^[0-9]+$/
            return numericRegex.test(event.target?.value ? event.target.value : event) || event.target.value === ''
        }
        if (propNamesNotSimbolsNumbrs.includes(propName)) {
            const regex = /^[^<>"{}()123456789*/|\\!@#$%^&]+$/

            return regex.test(event.target?.value ? event.target.value : event) || event.target.value === ''
                ? true
                : false
        }
        if (propNamesNotSimbols.includes(propName)) {
            const regex = /^[^<>"{}()*/|\\!@#$%^&]+$/

            return regex.test(event.target?.value ? event.target.value : event) || event.target.value === ''
        }
        if (propName === 'locality') {
            const regex = /^[^<>"{}*/|\\!@#$%^&]+$/

            return regex.test(event.target?.value ? event.target.value : event) || event.target.value === ''
                ? true
                : false
        }

        return true
    }

    function hasSpecialCharacters(event) {
        const specialCharacters = '<>""{}()*!@#$%^&'.split('')
        const value = event.target?.value || ''
        return Array.from(value).some((char) => specialCharacters.includes(char))
    }

    const onChangeAction = useCallback(
        (event) => {
            if (capitalizeFirstLetter) {
                event.target.value = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)
            }
            if (onChange) {
                onChange(event.target ? event.target.value : event)
            } else {
                if (multiple) {
                    onChangeMultiple(event)
                    return
                }
                if (props.type === 'checkbox') {
                    props.api.data[props.propName] = !props.api.data[props.propName]
                    return
                }
                // изменение значения инпута если функция валидации передана и введенныые данные пришли валидацию
                if (!!validateFilterInputs && validateFilterInputs(event, props.propName)) {
                    props.api.data[props.propName] = event.target ? event.target.value.toString() : event.toString()
                }
                // если функция валидации не передана то выполняем изменение значения инпута по умолчанию
                if (validateFilterInputs === undefined) {
                        if (validate(event, props.validName)) {
                            props.api.data[props.propName] = event.target
                                ? event.target?.value.toString()
                                : event.toString()
                        }
                    
                }

                if (autoSearch) {
                    props.api.setIsLoaded(false)
                }
            }
        },
        [onChange]
    )

    useEffect(() => {
        if (props.defaultValue) {
            onChangeAction(props.defaultValue)
        }
    }, [])

    return (
        <Box className={'fx-col'} sx={containerStyles}>
            {!!caption && (
                <Box mb={0.5}>
                    <Typography variant={'overline'}>{caption}</Typography>
                </Box>
            )}

            <InputField
                value={props.api.data[props.propName]}
                onChange={onChangeAction}
                // onPaste={onChangeAction}
                onPaste={props.paste ? onChangeAction : null}
                error={ignoreErrorColor ? false : error || props.api.showErrors && props.api.errors[props.propName]}
                styles={styles}
                {...props}
            />
        </Box>
    )
}

export default observer(FormInput)
