import {observable} from "mobx"

export const gState = observable({})

export function setGState(name, data) {
    gState[name] = data
}

export function rmGState(name) {
    delete gState[name]
}