import { openModalFn } from '../utils/controllers/ModalController'
import axiosRequest from '../utils/utils/apiRequest'
import buildParams from '../utils/utils/buildParams'

const getUrl = (path) =>
    window.SAT.config.apiUrl ? `${window.SAT.config.apiUrl}${path}` : window.location.origin + path

const apiRequest = (...requestParams) => {
    return axiosRequest(...requestParams)
}

export function signInRequest(params) {
    return apiRequest({
        url: getUrl('/account/login'),
        method: 'POST',
        jsonBody: params,
    })
}

export function authorize() {
    return apiRequest({
        url: getUrl(`/authorization/start`),
        method: 'GET',
    })
}

export function signUpRequest(params) {
    return apiRequest({
        url: getUrl('/account/invitedsignup'),
        method: 'POST',
        jsonBody: params,
    })
}

export function uploadOrders(params, queryParams) {
    return apiRequest({
        url: getUrl(`/orders/upload`),
        method: 'POST',
        formData: params,
        queryParams: queryParams,
    })
}

export function softUploadOrders(params, queryParams) {
    return apiRequest({
        url: getUrl(`/orders/softupload`),
        method: 'POST',
        formData: params,
        queryParams: queryParams,
    })
}

export function logoutApi() {
    return apiRequest({
        url: getUrl('/account/logout'),
    })
}

export function authorizationLogout() {
    return apiRequest({
        url: getUrl('/authorization/Logout'),
    })
}

export function getOrders(params) {
    const queryStingParams = buildParams(params)
    return apiRequest({
        url: getUrl(`/orders/getpaged?${queryStingParams}`),
    })
}
export function getAgreementOrders(params) {
    const queryStingParams = buildParams(params)
    return apiRequest({
        url: getUrl(`/agreements/loadedbyoperator?${queryStingParams}`),
    })
}

export function getDealers() {
    return apiRequest({
        url: getUrl('/dealers/getlist'),
        method: 'GET',
    })
}

export function updateStatus({ requestId, status }) {
    return apiRequest({
        url: getUrl(`/orders/${requestId}/updatestatus/${status}`),
        method: 'POST',
    })
}

export function getUserRoles() {
    return apiRequest({
        url: getUrl(`/roles/getuserroles`),
        method: 'GET',
        disableNotify: true,
    })
}

export function getUserInfo() {
    return apiRequest({
        url: getUrl(`/authorization/userinfo`),
        method: 'GET',
        disableNotify: true,
    })
}

export function getOrder(orderId) {
    return apiRequest({
        url: getUrl(`/orders/${orderId}`),
        method: 'GET',
    })
}

export function addOrder(params) {
    return apiRequest({
        url: getUrl(`/orders/add`),
        method: 'POST',
        jsonBody: params,
    })
}

export function addOrderByDealer(params) {
    return apiRequest({
        url: getUrl(`/orders/addfordealer`),
        method: 'POST',
        jsonBody: params,
    })
}

export function updateRequest(id, params) {
    return apiRequest({
        url: getUrl(`/orders/${id}/update`),
        method: 'POST',
        jsonBody: params,
    })
}

export function replaceRequest(id, params) {
    return apiRequest({
        url: getUrl(`/orders/${id}/replace`),
        method: 'POST',
        jsonBody: params,
    })
}

// export function attachImages(params) {
//     return apiRequest({
//         url: getUrl(`/images/attachimages`),
//         method: 'POST',
//         formData: params
//     })
// }

export function getImage({ requestId, imageIndex }) {
    return apiRequest({
        url: getUrl(`/orders/${requestId}/getimage/${imageIndex}`),
        method: 'GET',
    })
}

export function getSenders(showHided = false) {
    return apiRequest({
        url: getUrl(`/senders`),
        queryParams: { showHided },
        method: 'GET',
    })
}

export function addSenders(params) {
    return apiRequest({
        url: getUrl(`/senders/add`),
        method: 'POST',
        jsonBody: params,
    })
}

export function getSources() {
    return apiRequest({
        url: getUrl(`/sources`),
        method: 'GET',
    })
}

export function getStatistics() {
    return apiRequest({
        url: getUrl(`/statistics/ordersadded`),
        method: 'GET',
    })
}

export function getDefaultSenders() {
    return apiRequest({
        url: getUrl(`/senders/defaults`),
        method: 'GET',
    })
}

export function assignDealer(params) {
    return apiRequest({
        url: getUrl(`/orders/attachdealernew`),
        method: 'POST',
        jsonBody: params,
        disableNotify: true,
    })
}

export function getAuthors() {
    return apiRequest({
        url: getUrl(`/authors/new`),
        method: 'GET',
    })
}

export function getTerritory() {
    return apiRequest({
        url: getUrl(`/territory`),
        method: 'GET',
    })
}

export function declineOrder(params) {
    return apiRequest({
        url: getUrl(`/orders/decline`),
        method: 'POST',
        formData: params,
    })
}

export function markOrderAsUncompleted(params) {
    return apiRequest({
        url: getUrl(`/orders/markasuncompleted`),
        method: 'POST',
        formData: params,
    })
}

export function getComments(id) {
    return apiRequest({
        url: getUrl(`/comments/${id}`),
    })
}

export function getImages(orderId) {
    return apiRequest({
        url: getUrl(`/images`),
        queryParams: { orderId },
    })
}

export function switchCommentsVisibility(params) {
    return apiRequest({
        url: getUrl(`/comments/switchvisibility`),
        method: 'POST',
        jsonBody: params,
    })
}

export function addComment(params) {
    return apiRequest({
        url: getUrl(`/comments/add`),
        method: 'POST',
        jsonBody: params,
    })
}

export function getOperatorRequests(params) {
    const queryStingParams = buildParams(params)
    return apiRequest({
        url: getUrl(`/operators/paged?${queryStingParams}`),
        method: 'GET',
    })
}

export function takeOrder(params) {
    return apiRequest({
        url: getUrl(`/operators/takeorder`),
        method: 'POST',
        jsonBody: params,
    })
}

export function releaseOrder(params) {
    return apiRequest({
        url: getUrl(`/operators/releaseorder`),
        method: 'POST',
        jsonBody: params,
    })
}
//26575
export function maintenanceOrdersTake(params) {
    return apiRequest({
        url: getUrl(`/maintenance/MaintenanceOrders/take`),
        method: 'POST',
        jsonBody: params,
    })
}

export function maintenanceOrdersRelease(params) {
    return apiRequest({
        url: getUrl(`/maintenance/MaintenanceOrders/release`),
        method: 'POST',
        jsonBody: params,
    })
}

export function markOrderAsImpossible(params) {
    return apiRequest({
        url: getUrl(`/orders/markasimpossible`),
        method: 'POST',
        jsonBody: params,
    })
}

export function markOrderAsImpossibleDealer(params) {
    return apiRequest({
        url: getUrl(`/orders/markasimpossibledealer`),
        method: 'POST',
        jsonBody: params,
    })
}

export function orderToClarify(params) {
    return apiRequest({
        url: getUrl(`/orders/toclarify`),
        method: 'POST',
        jsonBody: params,
    })
}

export function orderCheck(params) {
    return apiRequest({
        url: getUrl(`/orders/check`),
        method: 'POST',
        jsonBody: params,
    })
}

export function orderClarifyData(params) {
    return apiRequest({
        url: getUrl(`/orders/clarifydata`),
        method: 'POST',
        formData: params,
    })
}

export function markOrderAsDuplicate(params) {
    return apiRequest({
        url: getUrl(`/orders/markasduplicate`),
        method: 'POST',
        jsonBody: params,
    })
}

export function markOrderAsDuplicateDealer(params) {
    return apiRequest({
        url: getUrl(`/orders/markasduplicatedealer`),
        method: 'POST',
        jsonBody: params,
    })
}
export function markOrderAsDuplicateAdministration(params) {
    return apiRequest({
        url: getUrl(`/orders/markasduplicateadministration`),
        method: 'POST',
        jsonBody: params,
    })
}

export function resetToCreated(params) {
    return apiRequest({
        url: getUrl(`/orders/resettocreated`),
        method: 'POST',
        jsonBody: params,
    })
}

export function resetToCreatedGroup(params) {
    return apiRequest({
        url: getUrl(`/orders/resettocreatedgroup`),
        method: 'POST',
        jsonBody: params,
    })
}

export function acceptOrder(params) {
    return apiRequest({
        url: getUrl(`/orders/accept`),
        method: 'POST',
        jsonBody: params,
    })
}

export function startInstallation(params) {
    return apiRequest({
        url: getUrl(`/orders/startinstallation `),
        method: 'POST',
        jsonBody: params,
    })
}

export function finishInstallation(params) {
    return apiRequest({
        url: getUrl(`/orders/finishinstallation `),
        method: 'POST',
        formData: params,
    })
}

export function bulkAccept(params) {
    return apiRequest({
        url: getUrl(`/orders/bulkaccept`),
        method: 'POST',
        jsonBody: params,
    })
}

export function getReports(params) {
    const queryStingParams = buildParams(params)
    return apiRequest({
        url: getUrl(`/reports/paged?${queryStingParams}`),
        method: 'GET',
    })
}

export function startDataChecking(params) {
    return apiRequest({
        url: getUrl(`/reports/startdatachecking`),
        method: 'POST',
        jsonBody: params,
    })
}

export function createReport(params) {
    return apiRequest({
        url: getUrl(`/reports/create`),
        method: 'POST',
        jsonBody: params,
    })
}

export function editReportComment(params) {
    return apiRequest({
        url: getUrl(`/reports/editcomment`),
        method: 'POST',
        jsonBody: params,
    })
}

export function setPriority(params) {
    return apiRequest({
        url: getUrl(`/orders/setpriority`),
        method: 'POST',
        jsonBody: params,
    })
}
export function updateFinancialSource(params) {
    return apiRequest({
        url: getUrl(`/Orders/UpdateFinancialSource`),
        method: 'POST',
        jsonBody: params,
    })
}

export function removeOrders(params) {
    return apiRequest({
        url: getUrl(`/reports/removeorders`),
        method: 'POST',
        jsonBody: params,
    })
}

export function reportForceClose(params) {
    return apiRequest({
        url: getUrl(`/reports/forceclose`),
        method: 'POST',
        jsonBody: params,
    })
}

export function startDataClarifying(params) {
    return apiRequest({
        url: getUrl(`/reports/startdataclarifying`),
        method: 'POST',
        jsonBody: params,
    })
}

export function endDataClarifying(params) {
    return apiRequest({
        url: getUrl(`/reports/enddataclarifying`),
        method: 'POST',
        jsonBody: params,
    })
}

export function endDataChecking(params) {
    return apiRequest({
        url: getUrl(`/reports/finishdatachecking`),
        method: 'POST',
        jsonBody: params,
    })
}

export function sendReport(params) {
    return apiRequest({
        url: getUrl(`/reports/send`),
        method: 'POST',
        jsonBody: params,
    })
}

export function receiveReport(params) {
    return apiRequest({
        url: getUrl(`/reports/receive`),
        method: 'POST',
        jsonBody: params,
    })
}

export function updateReceiver(params) {
    return apiRequest({
        url: getUrl(`/orders/update-receiver`),
        method: 'POST',
        jsonBody: params,
    })
}

export function startCheckingReport(params) {
    return apiRequest({
        url: getUrl(`/reports/startrecheck`),
        method: 'POST',
        jsonBody: params,
    })
}

export function reportToPay(params) {
    return apiRequest({
        url: getUrl(`/reports/topay`),
        method: 'POST',
        jsonBody: params,
    })
}

export function closeReport(params) {
    return apiRequest({
        url: getUrl(`/reports/close`),
        method: 'POST',
        jsonBody: params,
    })
}

export function getDealerInfo() {
    return apiRequest({
        url: getUrl(`/dealers/contractinfo`),
        method: 'GET',
    })
}

export function endOrderClarifyData(params) {
    return apiRequest({
        url: getUrl('/orders/endclarifydata'),
        method: 'POST',
        jsonBody: params,
    })
}

export function bulkSetPriority(params) {
    return apiRequest({
        url: getUrl('/orders/bulksetpriority'),
        method: 'POST',
        jsonBody: params,
    })
}

export function checkAllOrders(params) {
    return apiRequest({
        url: getUrl('/reports/checkorders'),
        method: 'POST',
        jsonBody: params,
    })
}

export function getCategories() {
    return apiRequest({
        url: getUrl('/categories'),
        method: 'GET',
    })
}

export function changeInstallationDateRequest(params) {
    return apiRequest({
        url: getUrl('/orders/changeinstallationdate'),
        method: 'POST',
        jsonBody: params,
    })
}

export function deleteImage(params) {
    return apiRequest({
        url: getUrl('/images/remove'),
        method: 'POST',
        jsonBody: params,
    })
}

export function restoreImage(params) {
    return apiRequest({
        url: getUrl('/images/restore'),
        method: 'POST',
        jsonBody: params,
    })
}

export function statisticForRegion(params) {
    const queryStingParams = buildParams(params)
    return apiRequest({
        url: getUrl(`/statistics/forregion?${queryStingParams}`),
        method: 'GET',
    })
}

export function getInstallationReasons() {
    return apiRequest({
        url: getUrl('/installationreasons'),
        method: 'GET',
    })
}

export function getUploadPhotoSettings() {
    return apiRequest({
        url: getUrl('/images/GetUploadSettings'),
        method: 'GET',
    })
}

export function resendForDataCheck(params) {
    return apiRequest({
        url: getUrl('/reports/resentfordatacheck'),
        method: 'POST',
        jsonBody: params,
    })
}

export function resendForDocumentCheck(params) {
    return apiRequest({
        url: getUrl('/reports/resentfordocumentcheck'),
        method: 'POST',
        jsonBody: params,
    })
}

export function getBackendVersion() {
    return apiRequest({
        url: getUrl('/version'),
    })
}

export function getInstallationSummary(params) {
    return apiRequest({
        url: getUrl('/statistics/ForOrdersInstalled'),
        method: 'POST',
        jsonBody: params,
    })
}

export function getCreatedSummary(params) {
    return apiRequest({
        url: getUrl('/statistics/ForOrdersCreated'),
        method: 'POST',
        jsonBody: params,
    })
}

export function addOrderData(param) {
    return apiRequest({
        url: getUrl('/orders/data/set'),
        method: 'POST',
        jsonBody: param,
    })
}

export function getTotalOrders() {
    return apiRequest({
        url: getUrl('/dashboard/statuses'),
        method: 'GET',
    })
}

export function getReportsStatistics(params = {}) {
    return apiRequest({
        url: getUrl('/Statistics/ReportStats'),
        method: 'POST',
        jsonBody: params,
    })
}

export function getRegionsStatistics() {
    return apiRequest({
        url: getUrl('/dashboard/regions'),
        method: 'GET',
    })
}

export function getDashboardReasons() {
    return apiRequest({
        url: getUrl('/dashboard/reasons'),
        method: 'GET',
    })
}

export function getDashboardSources(params) {
    return apiRequest({
        url: getUrl(`/dashboard/sources`),
        method: 'GET',
        queryParams: params,
    })
}

export function getConsolidation(params) {
    return apiRequest({
        url: getUrl(`/dashboard/consolidation`),
        method: 'GET',
        queryParams: params,
    })
}

export function getUserName() {
    return apiRequest({
        url: getUrl('/userinfo'),
    })
}

export function getPromiseTableData(params) {
    return apiRequest({
        url: getUrl('/orderinstallpromises/paged'),
        method: 'GET',
        queryParams: params,
    })
}

export function getEditableCountTotal(params) {
    return apiRequest({
        url: getUrl('/orderinstallpromises/totalcount'),
        method: 'GET',
        queryParams: params,
    })
}

export function getOpeningBalanced() {
    return apiRequest({
        url: getUrl('/orderinstallpromises/initialrecords'),
        method: 'GET',
    })
}

export function postEditableCountCreate(params) {
    return apiRequest({
        url: getUrl('/orderinstallpromises/create'),
        method: 'POST',
        jsonBody: params,
    })
}

export function postEditableCountUpdate(params) {
    return apiRequest({
        url: getUrl('/orderinstallpromises/update'),
        method: 'POST',
        jsonBody: params,
    })
}
export function markAgreementAsPrinted(params) {
    return apiRequest({
        url: getUrl('/agreements/markasprinted'),
        method: 'POST',
        jsonBody: params,
    })
}

export function getOrderHistory(id) {
    return apiRequest({
        url: getUrl(`/orderhistory/${id}`),
    })
}

export function getReportStorageTypes() {
    return apiRequest({
        url: getUrl('/reportstoragetypes'),
    })
}

export function getReportStorages() {
    return apiRequest({
        url: getUrl('/reportstorages'),
    })
}

export function addReportToStorage(params) {
    return apiRequest({
        url: getUrl('/reportstorages/reports/add'),
        method: 'POST',
        jsonBody: params,
    })
}

export function getMapRegionStats(params) {
    return apiRequest({
        url: getUrl('/location/bounded'),
        method: 'POST',
        jsonBody: params,
    })
}

export function wrrantyDiagnostic(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders/Diagnostic/complete/warranty'),
        method: 'POST',
        formData: params,
        // queryParams: queryParams,,
    })
}

export function noWrrantyDiagnostic(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders/Diagnostic/complete/nonwarranty'),
        method: 'POST',
        formData: params,
    })
}

export function getServiceOrders(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders/paged'),
        method: 'GET',
        queryParams: params,
    })
}

export function getServiceReasons() {
    return apiRequest({
        url: getUrl('/maintenance/maintenancereasons'),
        method: 'GET',
    })
}

export function getInstalledOrders(params) {
    return apiRequest({
        url: getUrl('/maintenance/installedorders'),
        method: 'GET',
        queryParams: params,
    })
}

export function createServiceOrder(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders'),
        method: 'POST',
        jsonBody: params,
    })
}

export function assignServiceOrderOnDealer(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders/maintenance/assign'),
        method: 'POST',
        jsonBody: params,
    })
}

export function assignServiceOrderOnDealerGroup(params) {
    return apiRequest({
        url: getUrl('/maintenance/MaintenanceOrders/maintenance/assign/group'),
        method: 'POST',
        jsonBody: params,
    })
}

export function getReworkServiceOrders(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders/paged/rework'),
        method: 'GET',
        queryParams: params,
    })
}

export function updateServiceOrderPut(params) {
    return apiRequest({
        url: getUrl('/maintenance/MaintenanceOrders/customer/data'),
        method: 'POST',
        jsonBody: params,
    })
}

export function addOrderByAdmin(params) {
    return apiRequest({
        url: getUrl('/orders/addloadedbyoperator'),
        method: 'POST',
        jsonBody: params,
    })
}

export function changeServiceEquipmentId(params) {
    return apiRequest({
        url: getUrl('/maintenance/MaintenanceOrders/bind'),
        method: 'POST',
        jsonBody: params,
    })
}

export function acceptServiceOrder(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders/Maintenance/accept'),
        method: 'POST',
        jsonBody: params,
    })
}

export function markServiceOrderAsDuplicate(params) {
    return apiRequest({
        url: getUrl('/maintenance/MaintenanceOrders/markasduplicate'),
        method: 'POST',
        jsonBody: params,
    })
}

export function getServiceOrderComments(params) {
    return apiRequest({
        url: getUrl(`/maintenance/Comments/${params}`),
        method: 'GET',
    })
}

export function addServiceOrderComment(params) {
    return apiRequest({
        url: getUrl('/maintenance/Comments'),
        method: 'POST',
        jsonBody: params,
    })
}

export function getServiceReceivers() {
    return apiRequest({
        url: getUrl('/maintenance/receivermodels'),
        method: 'GET',
    })
}

export function getRequestToReport(param) {
    const { reportId, sort, params } = param
    if (sort) {
        params.sort = sort
    }
    openModalFn['progress-backdrop']()
    return apiRequest({
        url: getUrl(`/reports/${reportId}/orders/suitable`),
        method: 'GET',
        queryParams: params,
    })
}

export function addRequestToReport(reportId, params) {
    return apiRequest({
        url: getUrl(`/reports/${reportId}/orders`),
        method: 'POST',
        jsonBody: params,
    })
}
export function getReceivers(params) {
    return apiRequest({
        url: getUrl(`/maintenance/receivers/${params}`),
        method: 'GET',

        // jsonBody: params,
    })
}

export function beginWorkWithOrder(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders/Maintenance/begin'),
        method: 'POST',
        jsonBody: params,
    })
}

export function completeOnSiteRepair(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders/Maintenance/complete/onsite/repair'),
        method: 'POST',
        formData: params,
    })
}

export function getServicePhotos(params) {
    return apiRequest({
        url: getUrl('/maintenance/Photos'),
        method: 'GET',
        queryParams: params,
    })
}

export function getServiceImage(params) {
    return apiRequest({
        url: getUrl('/maintenance/Photos/getimage'),
        method: 'GET',
        queryParams: params,
    })
}

export function sendToDiagnostic(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders/Maintenance/complete/diagnostic'),
        method: 'POST',
        formData: params,
    })
}
export function solvewithoutvisit(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders/Maintenance/solvewithoutvisit'),
        method: 'POST',
        jsonBody: params,
    })
}

export function improvementRequired(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders/Maintenance/improvementRequired'),
        method: 'POST',
        formData: params,
    })
}
export function serviceImpossible(params) {
    return apiRequest({
        url: getUrl('/maintenance/maintenanceorders/Maintenance/impossible'),
        method: 'POST',
        formData: params,
    })
}

export function getUploadServicePhotoSettings() {
    return apiRequest({
        url: getUrl('/maintenance/Photos/getuploadsettings'),
        method: 'GET',
    })
}

export function getFinancialSources() {
    return apiRequest({
        url: getUrl('/FinancialSources'),
        method: 'GET',
    })
}

export function updateFinancialSourceGroup(params) {
    return apiRequest({
        url: getUrl('/Orders/UpdateFinancialSourceGroup'),
        method: 'POST',
        jsonBody: params,
    })
}

export function orderFinancialSourcesStats(params) {
    return apiRequest({
        url: getUrl('/statistics/financial-sources'),
        method: 'GET',
        queryParams: params,
    })
}
export function getContracts() {
    return apiRequest({
        url: getUrl('/dealers/contracts'),
        method: 'GET',
    })
}

export function getColumnsForStatisticsActivity() {
    return apiRequest({
        url: getUrl('/monitoring/trackingactivities'),
        method: 'GET',
    })
}

export function getDataForStatisticsActivity(params) {
    return apiRequest({
        url: getUrl('/monitoring/activities/report'),
        method: 'GET',
        queryParams: params,
    })
}

export function getStatisticsDealerPerformanceHistoryToday(params) {
    return apiRequest({
        url: getUrl('/statistics/dealer-performance'),
        method: 'GET',
        queryParams: params,
    })
}

export function getStatisticsDealerPerformanceHistory(params) {
    return apiRequest({
        url: getUrl('/statistics/dealer-performance-history'),
        method: 'GET',
        queryParams: params,
    })
}

export function getStatisticsInstallationSubsidies(params) {
    return apiRequest({
        url: getUrl('/statistics/financial-sources-new'),
        method: 'GET',
        queryParams: params,
    })
}

export function getDashboardFinancialSources(params) {
    return apiRequest({
        url: getUrl('/dashboard/financial-sources'),
        method: 'GET',
        queryParams: params,
    })
}

export function updateWhiteList(params) {
    return apiRequest({
        url: getUrl('/location/financial-sources/whitelist'),
        method: 'POST',
        jsonBody: params,
    })
}
