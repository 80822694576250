import axios from 'axios'
import { serialize } from 'object-to-formdata'
import { notifyError } from '../../services/ToastifyService/ToastifyService'
import { gState } from '../controllers/GlobalStateController'

const getFormData = (object = {}) => {
    let formData
    if (object instanceof FormData) {
        formData = object
    } else {
        formData = new FormData()
        Object.entries(object).forEach(([key, value]) => {
            if (!!value) {
                if (value instanceof Blob) {
                    formData.append(object.fileArrayKey || 'files', value, value.name)
                } else {
                    if (Array.isArray(value)) {
                        value.forEach((el) => formData.append(key, el))
                    } else {
                        formData.append(key, value)
                    }
                }
            }
        })
    }
    return formData
}

export default function axiosRequest(config) {
    const {
        url,
        baseURL = '',
        method,
        queryParams = null,
        jsonBody = null,
        formData = null,
        data = null,
        headers = {},
        disableNotify = false,
    } = config

    if (!url) {
        throw new Error('"url" parameter required')
    }

    const getHeaders = () => {
        const resultHeaders = { ...headers }
        if (formData) {
            resultHeaders['Content-Type'] = 'multipart/form-data'
        }
        if (jsonBody) {
            resultHeaders['Content-Type'] = 'application/json; charset=utf-8'
        }
        return resultHeaders
    }
    const getData = () => {
        let data = null
        if (method?.toLowerCase() === 'post' || method?.toLowerCase() === 'patch') {
            if (formData) {
                data = getFormData(formData)
            } else {
                data = jsonBody || data || {}
            }
        }
        return data
    }

    return new Promise((success, error) => {
        axios
            .request({
                url,
                baseURL,
                method: method?.toLowerCase() || 'get',
                params: queryParams || null,
                data: getData(),
                headers: getHeaders(),
                withCredentials: true,
                // настройка сереализатора необходима для параметров в виде массива
                paramsSerializer: {
                    indexes: true,
                },
            })
            .then(
                (res) => {
                    // if (res.data.errorList && res.data.errorList.length) {
                    //     error(res.data.errorList[0])
                    // }
                    success(res.data)
                },
                (res) => error(res)
            )
    }).catch((res) => {
        if (res.response?.status === 401) {
            window.location.pathname = '/login'
            notifyError(gState['intl'].formatMessage({ id: 'Требуется авторизация' }))
            return
        }
        if (Array.isArray(res.response.data.Errors)) {
            res.response.data.Errors.forEach((el) => notifyError(`${el.id}: ${el.error}`))
        }
        
        if (!disableNotify) {
            if (res.response && res.response.data) {
                if (res.response.data.message) {
                    notifyError(res.response.data.message)
                }
                if (res.response.data.errors) {
                    res.response.data.errors.forEach((el) => notifyError(el))
                }
                // if (Array.isArray(res.response.data.Errors)) {
                //     res.response.data.Errors.forEach((el) => notifyError(`${el.id}: ${el.error}`))
                // }
            } else {
                notifyError(gState['intl'].formatMessage({ id: 'Запрос не может быть выполнен в данный момент' }))
                
            }
        }
        const resData = res.response.data

        throw new Error(resData ? JSON.stringify(resData) : res)
    })
}
