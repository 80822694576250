const fxStyles = {
    fx: {
        display: "flex"
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    alnCenter: {
        display: "flex",
        alignItems: "center",
    },
    jstCenter: {
        display: "flex",
        justifyContent: "center"
    },
    jstSB: {
        display: "flex",
        justifyContent: "space-between"
    },
    alnEnd: {
        display: "flex",
        alignItems: "flex-end"
    },
    jstEnd: {
        display: "flex",
        justifyContent: "flex-end"
    },
    col: {
        display: "flex",
        flexDirection: "column"
    }
}

export default fxStyles