import { toast } from 'react-toastify'
import errorHex from '../../assets/images/common/errorHex.svg'
import success from '../../assets/images/common/success.svg'
import { Typography, Box } from '@mui/material'
import './toastifyStyles.css'
import React from 'react'
import '../ToastifyService/toastifyStyles.css'
import ToastifyInfoComp from 'src/services/ToastifyService/TostifyInfo/TostifyInfo'
import { closeModalFn } from '../../utils/controllers/ModalController'

export function notifyError(msg, options) {
    toast.error(toastTemplate({ msg, icon: errorHex }), {
        ...options,
        progressStyle: { background: '#E01717' },
        icon: false,
        autoClose: 2500,
    })
}

export function notifyWarning(msg, options) {
    toast.warn(msg, { ...options })
}

export function notifySuccess(msg, options) {
    toast.success(toastTemplate({ msg, icon: success }), {
        ...options,
        progressStyle: { background: '#16B025' },
        icon: false,
        autoClose: 2500,
    })
}

export function notifyInfo({ text, textComp, action, timer = 2500, width, callback }) {
    let actionState = true
    const toastOptions = {
        position: 'bottom-left',
        autoClose: timer,
        hideProgressBar: false,
        closeOnClick: false,
        progress: undefined,
        theme: 'light',
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        onClose: () => {
            if (actionState) {
                return action()
            }
        },
        progressStyle: { background: '#1668B9' },
        icon: false,
        closeButton: false,
    }
    toast.info(
        ({ closeToast }) => (
            <ToastifyInfoComp
                width={width}
                closeToast={closeToast}
                text={text}
                textComp={textComp}
                onClose={() => {
                    actionState = false
                    closeToast()
                    callback && callback()
                    closeModalFn['progress-backdrop']()
                }}
            />
        ),
        toastOptions
    )
}

export function notifyAssignDealerInfo(msg, options) {
    toast.info(msg, { ...options, pauseOnFocusLoss: true, pauseOnHover: true })
}

// базовая верстка для тоста
const toastTemplate = ({ msg, icon }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                color: 'black',
                width: '384px',
                padding: '8px 8px 12px 8px',
                height: '58px',
            }}
        >
            <img alt='message icon' src={icon} />
            <Typography
                sx={{
                    fontSize: '14px',
                    fontWeight: 300,
                    lineHeight: 'normal',
                    color: 'black',
                }}
            >
                {msg}
            </Typography>
        </Box>
    )
}
