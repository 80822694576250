import React from 'react'
import { observer } from 'mobx-react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { css } from '@emotion/css'
import Box from '@mui/material/Box'
import commonStyles from '../../../../styles/commonStyles'
import Typography from '@mui/material/Typography'
import inputFieldStyles from '../styles/inputFieldStyles'
import { getStyles } from 'src/utils/utils/commonUtils'
import { FormControl, InputLabel, Tooltip } from '@mui/material'

function SelectInputField({ items, propName, styles, multiple, defaultValue, ...props }) {
    const classes = { ...getStyles(commonStyles, props), ...getStyles(inputFieldStyles) }
    const complexKey = typeof items[0]?.key === 'object'
    const renderValue = (value) => {
        const item = items.find((el) => {
            let itemValue = el.value || el
            return itemValue == props.value || value == itemValue
        })
        return (
            <Typography
                variant={props.size === 'small' ? 'body2' : 'subtitle1'}
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
                {!item
                    ? '-'
                    : complexKey
                    ? item?.key?.name
                    : (typeof item.key === 'function' ? item.key() : item.key) || item}
            </Typography>
        )
    }

    return (
        <FormControl fullWidth={props.fullWidth} size={props.size}>
            <InputLabel sx={{ color: props.error ? '#EA2020' : props.disabled ? 'rgba(0, 0, 0, 0.38)' : null }}>
                {props.label}
            </InputLabel>
            <Select
                className={css(classes.selectField, props.fullWidth && classes.fullWidth)}
                MenuProps={{ sx: classes.selectMenu(props) }}
                sx={{
                    width: props.width,
                    minWidth: props.minWidth,

                    ...(styles || {}),
                }}
                inputProps={{ error: props.error }}
                onClick={(e) => {
                    if (!multiple) {
                        e.stopPropagation()
                    }
                }}
                renderValue={props.renderValue || renderValue}
                defaultValue={props.defaultValue || null}
                {...props}
            >
                {items.map((item) => {
                    return (
                        <MenuItem
                            key={
                                complexKey
                                    ? item?.key?.name
                                    : item.key
                                    ? typeof item.key === 'function'
                                        ? item.key()
                                        : item.key
                                    : item
                            }
                            value={!item.value && typeof item.value === 'string' ? '' : item.value || item}
                            sx={{
                                ...classes.menuItem,
                                ...(complexKey && { height: '48px' }),
                            }}
                            onClick={(e) => {
                                if (!multiple) {
                                    e.stopPropagation()
                                }
                            }}
                        >
                            {complexKey ? (
                                <Box className={'fx-col'}>
                                    <Typography variant={'subtitle1'}>{item?.key?.name}</Typography>
                                    <Typography variant={'caption'}>{item?.key?.description}</Typography>
                                </Box>
                            ) : item.key ? (
                                typeof item.key === 'function' ? (
                                    item.key()
                                ) : item.key.length > 40 ? (
                                    <Tooltip
                                        title={item.key && item.key}
                                        placement={'top-start'}
                                        TransitionProps={{ timeout: 0 }}
                                    >
                                        <Typography>{item.key.substring(0, 40) + '...'}</Typography>
                                    </Tooltip>
                                ) : (
                                    <>
                                        {item.icon && <Box sx={{ marginRight: '4px' }}>{item.icon}</Box>}
                                        <Typography>{item.key}</Typography>
                                    </>
                                )
                            ) : (
                                item
                            )}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default observer(SelectInputField)
