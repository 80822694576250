import React from 'react'
import { Checkbox, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { observer } from 'mobx-react'

const CustomCheckboxInput = ({ label, onChange, propName, api, visible, variant, ...props }) => {
    const theme = useTheme()

    const handleChange = (e) => {
        if (props.disabled) {
            return
        }
        onChange(e)
    }
    return (
        visible && (
            <Box
                className={'fx-nowrap aln-center'}
                sx={{
                    cursor: 'pointer',
                    height: '100%',
                    border: variant !== 'text' ? `1px solid ${theme.palette.gray.main}` : 'none',
                    borderRadius: '4px',
                    padding: '14px 8px',
                }}
                onClick={handleChange}
            >
                <Checkbox sx={{ padding: '0 8px 0 0' }} checked={api.data[propName]} {...props} />
                <Typography
                    variant={'body1'}
                    sx={{
                        whiteSpace: 'nowrap',
                        fontWeight: '400',
                        color: props.disabled ? 'rgba(0, 0, 0, 0.38)' : null,
                    }}
                >
                    {label}
                </Typography>
            </Box>
        )
    )
}

export default observer(CustomCheckboxInput)
