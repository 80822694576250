import React, {useEffect, useState} from "react"
import DefaultAutocomplete from "src/components/customElements/InputField/components/DefaultAutocomplete"
import {observer} from "mobx-react"


function CustomAutocomplete({value, items, ...props}){
    const [handbookCollection, setHandbookCollection] = useState([])
    useEffect(() => {
        if (items) {
            if (value) {
                const newCollection = items.filter(el => {
                    return el.toLowerCase().includes(value.toLowerCase())
                })
                if (newCollection.length) {
                    setHandbookCollection(newCollection)
                } else {
                    setHandbookCollection([])
                }
            } else {
                setHandbookCollection(items)
            }
        }
    }, [value])

    useEffect(() => {
        if (items) {
            setHandbookCollection(items)
        }
    }, [])

    return (
       <DefaultAutocomplete
           value={value}
           handbookCollection={handbookCollection}
           {...props}
       />
    )

}

export default observer(CustomAutocomplete)



