import { observable, action, isObservable, toJS, runInAction, reaction, computed, makeObservable } from 'mobx'
import { closeModalFn } from '../controllers/ModalController'

class DataLoaderStore {
    constructor(config = {}) {
        this._init(config)
        this.loadParams = {}
        this.loadedData = {}

        makeObservable(this, {
            isLoaded: observable,
            isSetData: observable,

            isReady: computed,

            setIsLoaded: action,
            setData: action,
        })

        this.isReadyAsync = new Promise((resolve) => {
            reaction(
                () => this.isSetData === true,
                (value) => resolve()
            )
        })
    }

    static loadStatus = {
        none: 'none',
        loading: 'loading',
        loaded: 'loaded',
        error: 'error',
    }

    _action = () => Promise.reject(new Error('Action not defined'))

    _initStateProps = undefined

    _loadParams = {}

    _loadStatus = DataLoaderStore.loadStatus.none

    _loadTimeMs = 0

    _cachePeriodMs = undefined

    isLoaded = true

    get isReady() {
        return this.isSetData
    }

    isSetData = false

    setIsLoaded(value) {
        this.isLoaded = value
    }

    _throwErr(errMsg) {
        throw new Error(`DataLoaderStore: ${errMsg}`)
    }

    _init(config = {}) {
        if (config.initState) {
            this.initObservable(config.initState)
        }

        if (config.isLoaded) {
            this.setIsLoaded(config.isLoaded)
        }

        if (config.action && typeof config.action === 'function') {
            this._action = config.action
        }
    }

    initObservable(initState = {}) {
        if (typeof initState != 'object') {
            this._throwErr('initState has to be an object')
        }
        const props = Object.getOwnPropertyNames(initState)
        if (props.length === 0) {
            return
        }
        this._initStateProps = props
        const data = {}
        for (let p in props) {
            const propName = props[p]
            data[propName] = initState[propName]
        }
        this.data = observable(data)
    }

    setLoadedStatus() {
        this._loadStatus = DataLoaderStore.loadStatus.loaded
        this._loadTimeMs = new Date().getTime()
        this.setIsLoaded(true)
    }

    setLoadingStatus() {
        this.setIsLoaded(false)
        this._loadStatus = DataLoaderStore.loadStatus.loading
    }

    _setData(source) {
        if (!!!source) {
            this._throwErr('Invalid parameters')
        }

        if (typeof source != 'object') {
            this._throwErr('initObservable: source has to be an object')
        }

        if (!this.data || !isObservable(this.data)) {
            this.initObservable(source)
            return
        }

        if (this._initStateProps && this._initStateProps.length > 0) {
            runInAction(() => {
                for (let p in this._initStateProps) {
                    const propName = this._initStateProps[p]
                    if (source[propName] !== undefined) {
                        this.data[propName] = source[propName]
                    }
                }
            })
        }
    }

    setData(data) {
        if (data) {
            this._setData(data)
            this.isSetData = true
        }
        this.setLoadedStatus()
    }

    modifyQuery = (quryObj) => quryObj

    _load(params = this.loadParams) {
        this.setLoadingStatus()
        const query = this.modifyQuery(params)
        this.loadParams = query
        return this._action(query)
            .then(
                (data) => {
                    this.saveLoadedData(data)
                    this.setLoadedStatus()
                    this.setData(data)
                    this.postLoad()
                },
                (err) => (this._loadStatus = DataLoaderStore.loadStatus.error)
            ).finally(() => closeModalFn['progress-backdrop']())
            .then(() => closeModalFn['progress-backdrop']())
    }

    saveLoadedData(data) {
        const newD = JSON.stringify(data)
        this.loadedData = JSON.parse(newD)
    }

    rollBackData() {
        this.setData(this.loadedData)
    }

    postLoad() {}

    load(params = this.loadParams) {
        if (this._cachePeriodMs && new Date().getTime() - this._loadTimeMs > this._cachePeriodMs) {
            setTimeout(this.reLoad, 0)
            return
        }
        if (
            this._loadStatus === DataLoaderStore.loadStatus.loading ||
            this._loadStatus === DataLoaderStore.loadStatus.loaded
        ) {
            return this
        }
        this.setLoadingStatus()
        return this._load(params)
    }

    reLoad(params = this.loadParams) {
        return this._load(params)
    }

    getData() {
        const res = {}
        for (let p in this._initStateProps) {
            const propName = this._initStateProps[p]
            res[propName] = isObservable(this.data[propName]) ? toJS(this.data[propName]) : this.data[propName]
        }
        return res
    }
}

export default DataLoaderStore
