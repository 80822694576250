import {Box, Button, Typography} from '@mui/material'
import info_blue from 'src/assets/images/common/info_blue.svg'
import cross_dark_thin from 'src/assets/images/common/cross_dark_thin.svg'
import React from 'react'
import {observer} from 'mobx-react'
import {getStyles} from 'src/utils/utils/commonUtils'
import {toastifyInfoStyles} from 'src/services/ToastifyService/TostifyInfo/toastifyInfoStyles'
import {FormattedMessage} from 'react-intl/lib'

function ToastifyInfoComp({text, textComp, onClose, width}) {
    const classes = getStyles(toastifyInfoStyles)
    return (
        <Box sx={{...classes.container, width}}>
            <img alt='info' src={info_blue} />

            <Box className={'aln-center'} mr={1} sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                {textComp || <Typography sx={classes.text}>{text}</Typography>}
                <Button variant={'outlined'} color={'primary'} onClick={onClose} sx={classes.button}>
                    <FormattedMessage id={'Отмена'} />
                </Button>
            </Box>
            {/* <img onClick={onClose} src={cross_dark_thin} alt={'close'} style={{cursor: 'pointer'}} /> */}
        </Box>
    )
}

export default observer(ToastifyInfoComp)
