import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { getComp } from '../../../../utils/utils/DI'
import DefaultAutocomplete from 'src/components/customElements/InputField/components/DefaultAutocomplete'
import { isAdminType2 } from '../../../../services/AccountService'

function TerritoriesAutocomplete({ value, autocompleteOptions, error, ...props }) {
    
    const { type, territory, district, fin } = autocompleteOptions
    const [handbookCollection, setHandbookCollection] = useState([])

    const territoryStore = getComp('TerritoryStore')
    const statusStore = getComp('StatusesStore')
    const { setFinSourceId } = statusStore
    const { territories, } = territoryStore
    const { api, fullWidth, label, onChange, onPaste, placeholder, propName, settings, styles, variant, visible, disabled } =
        props
    const newProps = {
        api: api,
        fullWidth: fullWidth,
        label: label,
        onChange: onChange,
        onPaste: onPaste,
        placeholder: placeholder,
        propName: propName,
        settings: settings,
        styles: styles,
        variant: variant,
        visible: visible,
        disabled: disabled
    }

    const financialSourceIds = null
    useEffect(() => {
        if (territory && territories[territory]) {
            let collection = territories[territory]
            if (type === 'locality') {
                if (district && collection.Territory.Districts.some((el) => el.Name.toLowerCase() === district.toLowerCase())) {
                    collection = collection.Territory.Districts.find((el) => el.Name.toLowerCase() === district.toLowerCase()).Localities
                    let collection2 = fin && !isAdminType2() ?  collection.filter((locality) => locality.AllowedFinancialSourceIds.includes(fin) ):collection
                    collection = collection2.map((locality) => locality.Name);
                    
                    let loc = collection2.find((locality) => locality.Name === value)
                    setFinSourceId(loc?.AllowedFinancialSourceIds)
                } else {
                    collection = []
                }
            }
            if (type === 'district') {
                
                collection = collection.Territory.Districts.map((el) => el.Name)
            }

            if (value) {
                const newCollection = collection.filter((el) => {
                    return el.toLowerCase().includes(value.toLowerCase())
                })
                if (newCollection.length) {
                    setHandbookCollection(newCollection)
                } else {
                    setHandbookCollection([])
                }
            } else {
                setHandbookCollection(collection)
            }
        }
    }, [value, territory, district, fin ])

    useEffect(() => {
        territoryStore.isReadyAsync.then(() => {
            if (type === 'territory') {
                setHandbookCollection(territoryStore.collection)
            }
        })
    }, [])

   
    return (
        <DefaultAutocomplete
            value={value}
            handbookCollection={handbookCollection}
            error={props.offError ? false : (error || !handbookCollection.some((el) => el === value)) && !props.disabled}
            {...newProps}
        />
    )
}

export default observer(TerritoriesAutocomplete)
