export const toastifyInfoStyles = () => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: '384px',
        padding: '8px 8px 12px 8px',
        height: '68px',
    },
    text: {
        fontSize: '14px',
        fontWeight: 300,
        lineHeight: 'normal',
        color: 'black',
        marginRight: '16px',
    },
    button: {
        height: '37px',
        boxSizing: 'content-box',
        marginLeft: '8px',
        padding: '0 8px',
        fontFamily: 'PT Root UI',
        fontSize: '15px',
    },
})
