import { action, computed, observable, reaction, makeObservable } from 'mobx'
import DataLoaderStore from './DataLoaderStore'
import { getValidatorData, getValidators } from '../services/ValidationService'
import moment from 'moment/moment'

const throwErr = (errMsg) => {
    throw new Error(`FormStore: ${errMsg}`)
}

class FormStore extends DataLoaderStore {
    changes = new Map()
    isValid = true
    showErrors = false
    selectedFilters = []
    dateNames = []

    constructor(config = {}) {
        super(config)
        this.initState = config?.initState
        makeObservable(this, {
            changes: observable,
            isValid: observable,
            selectedFilters: observable,
            showErrors: observable,
            isChanged: computed,
            setChangesCounter: action,
            checkIsValid: action,
            setError: action,
        })

        this.initValidateData(config.validateData)
        this.initFormObservable()
        this.initChangeReaction()
    }

    setSelectedFilters = () => {
        this.selectedFilters = Object.entries(this.data).reduce((acc, [propName, value]) => {
            if (value) {
                if (Array.isArray(value)) {
                    value.forEach((multiselectValue) => acc.push({ propName, value: multiselectValue }))
                } else if (
                    this.dateNames.length &&
                    this.dateNames.some((dateArr) => dateArr.some((dateName) => dateName === propName))
                ) {
                    this.dateNames.forEach(([nameFrom, nameTo]) => {
                        if (nameFrom === propName || nameTo === propName) {
                            const dateValue = acc.find((el) => el.propName === nameFrom)
                            if (dateValue) {
                                if (propName === nameTo) {
                                    dateValue.value = dateValue.value + ' - ' + moment(value).format('DD.MM.YYYY')
                                } else {
                                    dateValue.value = moment(value).format('DD.MM.YYYY') + ' - ' + dateValue.value
                                }
                            } else {
                                acc.push({
                                    propName: nameFrom,
                                    value: moment(value).format('DD.MM.YYYY'),
                                    to: propName === nameTo,
                                })
                            }
                        }
                    })
                } else {
                    acc.push({ propName, value })
                }
            }
            return acc
        }, [])
    }

    bringTheDataToTheSelectedFilters = () => {
        Object.entries(this.data).forEach(([propName, value]) => {
            if ((Array.isArray(value) && value.length) || (!Array.isArray(value) && value)) {
                if (Array.isArray(value)) {
                    const selectedFilters = this.selectedFilters.filter((el) => el.propName === propName)
                    if (value.length) {
                        if (!selectedFilters.length) {
                            this.data[propName] = []
                        } else {
                            this.data[propName] = selectedFilters.map((el) => el.value)
                        }
                    } else if (!value.length && selectedFilters.length) {
                        this.data[propName] = selectedFilters.map((el) => el.value)
                    }
                } else if (
                    this.dateNames.length &&
                    this.dateNames.some((dateArr) => dateArr.some((dateName) => dateName === propName))
                ) {
                    const selectedFilter = this.selectedFilters.find((el) => el.propName === propName)
                    const [nameFrom, nameTo] = this.dateNames.find(([nameFrom, nameTo]) => {
                        return nameFrom === propName || nameTo === propName
                    })
                    if (!selectedFilter) {
                        switch (propName) {
                            case nameTo: {
                                const filter = this.selectedFilters.find((el) => el.propName === nameFrom)
                                if (filter) {
                                    if (filter.to) {
                                        this.data[propName] = moment(filter.value, 'DD.MM.YYYY')
                                    } else if (filter.value.includes('-')) {
                                        const dates = filter.value.split(' - ')
                                        this.data[propName] = moment(dates[1], 'DD.MM.YYYY')
                                    } else {
                                        this.data[propName] = null
                                    }
                                }
                                break
                            }
                            case nameFrom: {
                                this.data[propName] = null
                                break
                            }
                        }
                    } else {
                        if (selectedFilter.value.includes('-')) {
                            const dates = selectedFilter.value.split(' - ')
                            this.data[propName] = moment(dates[0], 'DD.MM.YYYY')
                            this.data[nameTo] = moment(dates[1], 'DD.MM.YYYY')
                        } else if (selectedFilter.to) {
                            this.data[nameTo] = moment(selectedFilter.value, 'DD.MM.YYYY')
                        } else {
                            this.data[propName] = moment(selectedFilter.value, 'DD.MM.YYYY')
                        }
                    }
                } else {
                    const selectedFilter = this.selectedFilters.find((el) => el.propName === propName)
                    if (selectedFilter) {
                        this.data[propName] = selectedFilter.value
                    } else {
                        this.data[propName] = ''
                    }
                }
            }
        })
    }

    deleteFilter = (name, multiselectValue, from) => {
        const initValue = this.initState[name]
        const compareValue = initValue === null ? 'date' : Array.isArray(initValue) ? 'multiselect' : 'string'
        switch (compareValue) {
            case 'date': {
                this.data[name] = null
                const dateToName = from? name.replace('From') : name.includes('From') ? name.replace('From', 'To') : name.replace('from', 'to')
                this.data[dateToName] = null
                break
            }
            case 'multiselect': {
                this.data[name] = this.data[name].filter((el) => el !== multiselectValue)
                break
            }
            default: {
                this.data[name] = ''
            }
        }
    }

    clearSelectedFilters = () => {
        this.selectedFilters = []
    }

    onChangeMultiple = (event) => {
        const { value, name } = event.target || event
        this.data[name] = typeof value === 'string' || typeof value === 'number' ? this.data[name].split(',') : value
        
    }

    onClearMultiple = (name) => {
        this.data[name] = []
    }

    get isChanged() {
        return this.changes.size > 0
    }

    initChangeReaction() {
        for (const p in this.data) {
            reaction(() => this.data[p], this.setChangesCounter.bind(this, p))
        }
    }

    setChangesCounter(p) {
        if (this.data[p] !== this.loadedData[p]) {
            this.changes.set(`${p}`, true)
        }
        if (this.data[p] === this.loadedData[p]) {
            this.changes.delete(`${p}`)
        }
    }

    onFormObservableInit() {}

    checkIsValid() {
        var isValid = true
        for (let p in this._validateDataProps) {
            const propName = this._validateDataProps[p]
            if (this.errors[propName]) {
                isValid = false
                break
            }
        }
        this.isValid = isValid
    }

    validateProp = (value, propName) => {
        if (!propName == null) {
            throwErr('propName not defined')
        }
        if (!this.formData) {
            throwErr('formData not defined')
        }

        if (!this.formData[propName] || !this.formData[propName].isActive) {
            return ''
        }

        const { validators } = this.formData[propName]

        if (!validators || validators.length === 0) {
            throwErr(`Validators for ${propName} are not defined in formData`)
        }
        for (let i = 0; i < validators.length; i += 1) {
            if (validators[i].validatorName === 'required' && !this.formData[propName].required) {
                return ''
            }
            const er = validators[i](value, this)
            if (er) {
                return er
            }
        }
        return ''
    }

    checkFormField(propName, validateData) {
        if (!propName in this.data) {
            throwErr(`Validation property ${propName} not found in initState`)
        }
        const type = validateData[propName].type
        const validators = validateData[propName].validators
        const required = validateData[propName].required || false
        if (!type && (!validators || validators.length === 0) && !required) {
            throwErr(`Define default_type, validators or set required for field ${propName}`)
        }
    }

    initValidateData(validateData = {}) {
        if (!validateData) {
            throwErr('validateData not defined')
        }
        if (typeof validateData != 'object') {
            throwErr('validateData has to be an object')
        }
        this._validateDataProps = Object.getOwnPropertyNames(validateData)
        if (this._validateDataProps.length === 0) {
            throwErr(
                'validateData has to contain at least one property with following format: propName: { type: tYPE_VALUE, validators: VALIDATORS_VALUE, required?:true|false } '
            )
        }

        this.formData = {}
        for (let p in this._validateDataProps) {
            const propName = this._validateDataProps[p]
            this.checkFormField(propName, validateData)
            const srvValidators = getValidators(validateData[propName].type, validateData[propName].required)
            const vData = getValidatorData(validateData[propName].type)
            const validators = validateData[propName].validators || []
            this.formData[propName] = {
                required: validateData[propName].required || false,
                validators: [...srvValidators, ...validators],
                type: validateData[propName].type,
                maxLength: vData ? vData.maxLength : undefined,
                isActive: validateData[propName].isActive || true,
            }
        }
    }

    _setValidate(propName, val) {
        if (!val) {
            if (this.errors && this.errors[propName]) {
                this.errors[propName] = ''
            }
        } else {
            if (this.data && this.data[propName] !== undefined) {
                this.errors[propName] = this.validateProp(this.data[propName], propName)
            }
        }
    }

    setValivateActive(propName, value) {
        const val = !!value
        if (this.formData && this.formData[propName]) {
            this.formData[propName].isActive = val
        }
        this._setValidate(propName, val)
    }

    setValivateRequired(propName, value) {
        const val = !!value
        if (this.formData && this.formData[propName]) {
            this.formData[propName].required = val
        }
        this._setValidate(propName, val)
    }

    setError = (propName, errorValue) => {
        this.errors[propName] = errorValue
    }

    initFormObservable() {
        if (this._validateDataProps.length === 0) {
            this._throwErr('initState is empty')
        }
        var errors = {}
        for (let p in this._validateDataProps) {
            const propName = this._validateDataProps[p]
            errors[propName] = ''
        }
        this.errors = observable(errors)

        for (let p in this._validateDataProps) {
            const propName = this._validateDataProps[p]
            reaction(
                () => this.errors[propName],
                (err) => {
                    this.checkIsValid()
                }
            )
            reaction(
                () => this.data[propName],
                (value) => {
                    this.errors[propName] = this.validateProp(value, propName)
                }
            )
            this.errors[propName] = this.validateProp(this.data[propName], propName)
        }
        this.onFormObservableInit()
    }
}

export default FormStore
